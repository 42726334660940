
.container {
  position: relative;
  width: 300px;
  height: 160px;
  margin: 0 auto;
  .graph {
    width: 100%;
    height: 100%;
  }
}
