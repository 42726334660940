@import '../Common.scss';

.container {
  width: 100%;
  .typeLink {
    color: $textSub;
    margin-top: 16px;
    a {
      color: $textSub;
      text-decoration: none;
      &:visited {
        color: $textSub;
      }
    }
  }
}