@import '../Common.scss';

.container {
  background-color: $bgPastel;
  margin: 0;
  padding: 0 24px 80px 24px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  .doctor {
    margin-top: 24px;
    width: 200px;
    height: 200px;
  }  
  .block {
    margin-top: 48px;
    h2 {
      height: 41px;
      font-size: 24px;
      line-height: 41px;
      font-weight: bold;
      color: #D1636D;
    }
    p {
      text-align: left;
      margin: 24px 0 0 0;
      text-align: justify;
      text-justify: inter-ideograph;
    }  

    .missionText{
      font-size: 20px;
      font-weight: bold;
      height: 34px;
      line-height: 34px;
      margin-top: 24px;
    }
    .personBlock {
      margin-top: 40px;
      .photo {
        width: 120px;
        height: 120px;
      }
      .name {
        font-size: 18px;
        font-weight: bold;
        margin-top:24px;
      }
      .ename {
        font-size: 15px;
        margin-top: 8px;
      }  
    }
  }
}
