
.container {
  position: relative;
  width: 100%;

  .labelContainer {
    position: relative;
    width: 100%;  
    height: 10px;
    font-size: 13px;

    .minLabel {
      position: absolute;
      left: 0px;
    }
    
    .maxLabel {
      position: absolute;  
      right: 0px;
    }
  
  }
}
