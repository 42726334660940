// Takram Confidential
// Copyright (C) 2019-Present Takram

@import './components/Common.scss';

html, body {
  background-color: $bgPastel;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Yu Gothic Medium', 'YuGothic', sans-serif, 'Apple Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
