@import '../Common.scss';

.container {
  background-color: $bgPastel;
  margin: 0;
  padding: 0 0 20px 0;
  width: 100%;
  text-align: center;

}
