@import '../Common.scss';

.container {
  width: 100%;
  .formContainer {
    box-sizing: border-box;
    padding: 32px 32px;
    h2 {
      box-sizing: border-box;
      font-size: 18px;
      margin: 0;
      text-align: center;
    }    
    p {
      font-size: 15px;
      text-align: center;
      color: #555;
    }
    a {
      color: #555;
    }
    .link {
      padding: 0 4px;
      color: $primaryBlue;
      font-weight: bold;
    }
    .messageContainer {
      .message {
        color: $primaryBlue; 
      }
      .errorMessage {
        color: $accentRed;
      }  
    }    
    .resetPass {
      text-align: right;
      font-size: 12px;
      color: $primaryBlue;
    }
    .buttonContainer {
      box-sizing: border-box;
      margin: 32px auto;
      &>* {
        margin: 0 0 8px 0;
      }
      p {
        text-align: center;
      }
    }
  
  }
}
