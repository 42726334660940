@import '../Common.scss';

.container {
  box-sizing: border-box;
  background-color: $bgPastel;
  margin: 0 0 0 0;
  padding: 56px 0 0 0;
  width: 100%;
  height: 100%;

  h1 {
    font-size: 24px;
    text-align: center;
    margin: 16px 0;
  }
  h2 {
    font-size: 20px;
    text-align: center;
    margin: 8px 0;
  }
  h3 {
    font-size: 18px;
    text-align: center;
    margin: 8px 0;
  }
}