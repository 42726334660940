@import '../Common.scss';

.container {
  height: 500px; // Override from js
  position: relative;
  text-align: center;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  display: grid;
  grid-template-rows: 60px calc(100% - 150px) 90px;
  // background-color: yellow;

  .back {
    position: absolute;
    top: 0px;
    left: 0px;
    // background-color: yellowgreen;
    text-align: left;
  }
  .questionCell {
    // background-color: aqua;
    line-height: 30px;
    padding: 30px 0 0 0;
  }
  .childrenCell {
    // background-color: blue;
    overflow: hidden;
    // padding: 0px 24px 0px 24px;
    // font-size: 15px;
    // overflow-y: auto;
  }
  .actionCell {
    // background-color: red;
    .progress {
      font-size: 18px;
      padding: 8px 0 8px 0;
    }
    .next {
      padding: 0 0 0 0;
    }
  }
}
