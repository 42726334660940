@import '../Common.scss';

.container {
  margin: 0;
  padding: 0 32px;
  box-sizing: border-box;
  text-align: justify;
  h2,h3 {
    text-align: left;
  }
  ol {
    margin-left: -20px;
  }
}
