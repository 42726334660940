
.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 24px 24px 0 24px;
  box-sizing: border-box;
  overflow-y: auto;  
  .buttons {
    .button {
      margin-bottom: 12px;
    }
    padding-bottom: 48px;
  }
}
