@import '../Common.scss';

.container {
  margin: 0;
  text-align: left;
  position:relative;
  .recommendBg{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 240px;
    background-color: #82B3EC;
  }
  .recommendImage{
    position: relative;
    background-image: url("../../assets/images/illust/line_recommend.svg");
    background-repeat: no-repeat;
    background-position: center center;
    height: 260px;
    width: 100%;
    margin: auto;
  }
  .suggest {
    box-sizing: border-box;
    text-align: left;
    background-color: $bgWhite;
    border: solid 1px $stroke;
    border-radius: 8px;
    padding: 16px;
    // width: 280px;
    margin: 16px 16px;
    font-size: 16px;
    font-weight: bold;
    white-space: pre-wrap;
  }
  .buttons {
    margin: 32px auto;
    width: 300px;
    &>* {
      margin: 8px 0;
    }
  }
}
