@import '../Common.scss';

.container {
  text-align: center;  
  width: 300px;
  font-size: 14px;
  margin: 0 auto;
  line-height: 32px;
  border-radius: 16px;
  .description {
    color: $textSub;
    width: 240px;
    margin: 16px auto;
    line-height: 24px;
  }
  .buttons {
    width: 200px;
    margin: 0 auto;
    .button {
      margin-bottom: 16px;
    }
  }
}
