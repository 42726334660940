@import '../Common.scss';

.container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  // z-index: 1000;

  .menuIcon {
    position: absolute;
    top: 8px;
    right: 16px;
    background-image: url("../../assets/images/icon/menu_open.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 40px;
    height: 40px;
    pointer-events: auto;
    &.isOpen {
      background-image: url("../../assets/images/icon/menu_close.svg");
    }
  }
  .menuContainer {
    background-color: $bgPastel;
    position:fixed;
    box-sizing: border-box;
    top: 56px;
    width: 100%;
    height: calc(100vh - 56px);
    pointer-events: auto;
    z-index: 1000;
    padding: 24px;
  }
}
