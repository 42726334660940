@import '../Common.scss';

.container {
  background: -moz-linear-gradient(to bottom left,#FFE1E4 10%, #C7EBFF 90%);
  background: -webkit-linear-gradient(to bottom left,#FFE1E4 10%, #C7EBFF 90%);
  background: linear-gradient(to bottom left,#FFE1E4 10%, #C7EBFF 90%);
  text-align: center;
  overflow:hidden;
  .doctor {
    margin: 24px auto 12px auto;
    background-image: url("../../assets/images/illust/doctor.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 120px;
    height: 120px;
    &.hidden {
      display: none;
    }
  }
  .message {
    margin: 12px auto 24px auto;
    font-size: 16x;
    font-weight: bold;
    white-space: pre-wrap;
  }
  .buttons {
    margin: 24px auto;
    padding: 0 32px;
    max-width: 320px;
    display: grid;
    row-gap: 18px;
  }
  .red {
    font-weight: bolder;
    color: #ED1D1D;
  }
}
