@import '../Common.scss';

.container {
  font-size: 14px;
  margin: 0;
  .baloonContainer {
    padding: 8px;
    .buttons {
      display: grid;
      margin: 9px 0 0 0;
      grid-template-columns: 1fr 1fr;
      column-gap: 8px;
      div {
        font-weight: bold;
        text-align: center;
        height: 32px;
        line-height: 32px;
        border-radius: 8px;
      }
      .disagree {
        color: $accentRed;
        border: solid 2px $accentRed;
      }
      .agree {
        color: $primaryBlue;
        border: solid 2px $primaryBlue;
      }  
    }
    }
}
