@import '../Common.scss';

.container {
  background-color: $bgPastel;
  margin: 0;
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
  h3 {
    padding-top: 64px;
    margin-top: -56px;  // For adjusting anchor link position    
    text-align: center;
  }
  a {
    width: 100%;
    background-color:$bgWhite;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    &:active { color: $textBlack; }
    &:link { color: $textBlack; }
    &:visited { color: $textBlack; }
  }  
  .typeList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    row-gap: 8px;

  }
  .typeBlock{
    position: relative;
    text-align: center;
    padding-top: 72px;
    margin-top: -56px;  // For adjusting anchor link position
    margin-bottom: 16px;
    pointer-events: none;
    .typeImage{
      width: 280px;
      margin: 0 auto;
    }
    .typeNumber{
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 6px 0;
      color: $textGray;
    }
    .typeName{
      font-size: 18px;
      font-weight: bold;
      line-height: 27px;
      margin: 0 0 6px 0;
    }
    .typeText{
      font-size: 15px;
      text-align: left;
      width: 290px;
      margin: 0 auto;
    }
  }

  .backLink {
    position: fixed;
    background-color: $bgPastel;
    bottom: 16px;
    right:16px;
    width: 40px;
    line-height: 40px;
    border: solid 1px $stroke;
    border-radius: 8px;
    pointer-events: all;
    &.hide {
      display: none;
    }
  }

}
