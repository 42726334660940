@import '../Common.scss';

.container {
  margin: 24px 0 0 0;
  .topContent {
    // margin-bottom: 32px;
    font-size: 13px;
    .doctorText {
      padding-bottom: 10px;
      border-bottom: solid 1px $border;
    }
    a {
      color: $textBlack;
      text-decoration: none;
      font-weight: bold;
      &:visited {
        color: $textBlack;
      }
    }  
    .article {
      display: grid;
      grid-template-columns: 1fr 80px;
      column-gap: 16px;
      margin: 16px 0;
      .thumb {
        width: 80px;
        height: 58px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .readBtn{
      text-align: center;
      a {
        color: $bgWhite;
      }
    }
  }
  .otherList{
    margin-top: 32px;
    .button {
      margin: 32px auto 0 auto;
      width: 200px;
    }
  }
}
