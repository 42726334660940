@import '../Common.scss';

.container {
  position: relative;
  .overlay {
    position: absolute;
    left: 30px;
    top: 0;
    width: calc(100% - 60px);
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, .25); 
    .doctor {
      width: 48px;
      height: 48px;
      margin: 0 auto;
      background-image: url("../../assets/images/icon/chat_doctor.svg");
    }
    .text {
      font-size: 14px;
      margin: 10px 0;
    }
    .buttons {
      display: inline-block;
      width: 100%;
    }
  }
  .overlayThin {
    position: absolute;
    left: 10px;
    top: 0;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, .25); 
    .text {
      font-size: 13px;
      b {
        font-weight: bold;
        color: $primaryBlue;
      }
    }
  }
}
