@import '../Common.scss';

.container {
  display: grid;
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: 48px 1fr;
  grid-column-gap: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: solid 1px $border;
  .authIcon {
    width: 48px;
    height: 48px;
    background-image: url("../../assets/images/icon/auth_rabbit.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
  .authStatus {
    height: 32px;
    text-align: left;
    .name {
      height: 32px;
      font-size: 18px;
      color: $textBlack;
    }
    .email {
      height: 16px;
      line-height: 16px;
      font-size: 15px;
      color: $textSub;
    }
  }
}
