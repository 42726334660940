@import '../Common.scss';

.container {
  margin: 32px 0 0 0;
  .otherList{
    margin-top: 32px;
    .button {
      margin: 32px auto 0 auto;
      width: 200px;
    }
  }
}
