@import '../Common.scss';

.container {
  background-color: $accentPink;
  border: solid 1px $accentRose;
  border-radius: 8px;
  padding: 16px;
  margin: 0;
  h3 {
    font-size: 15px;
    height: 20px;
    line-height: 20px;
    margin: 0;
  }
  p {
    margin: 20px 0 0 0;
  }
}
