@import '../Common.scss';

.container{
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
  a {
    color: $textBlack;
    text-decoration: none;
    &:visited {
      color: $textBlack;
    }
  }
  .content {
    background-color: $bgWhite;
    position: relative;
    border: solid 1px $border;
    width: 100%;
    height: 128px;
    
    padding: 16px 16px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 120px;
    grid-column-gap: 8px;

    .thumb {
      width: 120px;
      height: 88px;
      margin: auto 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      .title {
        width: 100%;
        font-size: 15px;
        line-height: 22px;
        font-weight: bold;
        text-align: left;
      }
      .tags {
        margin-top: 8px;
        position: absolute;
        left: 16px;
        bottom: 16px;
        .tag {
          float: left;
          box-sizing: border-box;
          color: $textSub;
          font-size: 12px;
          height: 20px;
          line-height: 20px;
          margin-right: 8px;
        }
      }
    }
    .favoButton {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 40px;
      height: 40px;
    }
  }
}