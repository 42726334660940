@import '../Common.scss';

.container{
  font-size: 15px;
  img {
    width: 100%;
  }
.body {
    padding: 0 24px;
    h2 {
      margin: 16px 0;
      text-align: left;
      font-size: 20px;
    }  
    .block {
      h1 {
        text-align: left;
        font-size: 24px;
      }
      h2 {
        text-align: left;
        font-size: 20px;
        margin: 32px 0 16px 0;
      }
      h3 {
        text-align: left;
        font-size: 16px;
        margin: 32px 0 16px 0;
      }
      ul {
        padding:0 0 0 20px;
        margin: 5px 0;
        list-style-type: disc;
        list-style-position: outside; 
      }  
      p {
        font-size: 15px;
        margin: 16px 0;
        line-height: 24px;
      }
      b {
        font-weight: bold;
        background-color: $primarySky;
      }
      br {
        display: block;
        content: "";
        padding: 2px;
      }
    }
  
    .suggestList {
      margin-bottom: 32px;
      h2 {
        margin: 32px;
        text-align: center;
      }
      &.hide { display: none; }
    }
  }

  .articleActions {
    margin: 32px 0;
    .actionButton {
      margin: 16px auto;
      width: 240px;
      &.hide { display: none; }
    }  
  }
  .separator {
    margin: 64px 0;
    text-align: center;
  }

}