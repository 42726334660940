@import '../Common.scss';

.container {
  background-color: $bgPastel;
  text-align: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  .block {
    margin: 0 16px 32px 16px;
    box-sizing: border-box;
    overflow: hidden;
    p {
      text-align: left;
    }
  }
  .cardBlock {
    box-sizing: border-box;
    background-color: $bgWhite;
    margin: 16px;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, .25);
    .rabbitTable {
      display: grid;
      grid-template-columns: 50% 50%;
      margin: 0 8px;
    }
    .rabbitText {
      margin: 16px 0;
    }
    .actions {
      margin: 0 24px;
    }
    .actions > * {
      margin: 0 0 16px 0;
    }
  }
  .suggestion {
    font-size: 14px;
  }
}
