@import '../Common.scss';

.container {
  width: 100%;
  .levelText {
    height: 36px;
    line-height: 36px;
    margin: 16px 0 8px 0;
    font-size: 24px;
    font-weight: bold;
  }
  .rankText { 
    font-size: 12px;
    margin: 8px 0 8px 0;
    .myRank{
      font-size: 18px;
      font-weight: bold;
    }
  }
  .bar {
    background-color: $accentPink;
    height: 24px;
    width: 100%;
    border-radius: 8px;
    .valueBar {
      background-color: $accentRed;
      height: 24px;
      width: 50%;
      border-radius: 8px;
    }
  }
}