@import '../Common.scss';

.container {
  background-color: $bgPastel;
  box-sizing: border-box;
  font-size: 15px;
  margin: 0;
  padding: 24px;
  text-align: left;
  width: 100%;
  h3 {
    line-height: 26px;
    text-align: left;
  }
  a { 
    color: $primaryBlue; 
    font-size: 16px;
    line-height: 26px;
    text-decoration: none;
    &:visited {
      color: $primaryBlue; 
    }
  }
  .poweredby {
    text-align: right;
  }
  
}
