@import '../Common.scss';

.container {
  height: 500px; // Override from js
  width: 100%;
  background-color: $bgPastel;
  color: $textBlack;
  text-align: center;
  margin: 0;
  padding: 0;
}
