@import '../Common.scss';

.container{
  text-align: center;
  margin: 64px auto;
  .buttons {
    margin: 0 auto;
    width: 240px;
    display: grid;
    grid-row-gap: 16px;
  }
}