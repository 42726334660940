@import '../Common.scss';

.container {
  margin: 0;
  padding: 0 0 0 0;
  .accuracy {
    display: inline-block;
    width: 40px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    &.lv1 { background-image: url("../../assets/images/icon/accuracy_1.png"); }
    &.lv2 { background-image: url("../../assets/images/icon/accuracy_2.png"); }
    &.lv3 { background-image: url("../../assets/images/icon/accuracy_3.png"); }
    &.lv4 { background-image: url("../../assets/images/icon/accuracy_4.png"); }
  }
}
