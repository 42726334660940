
.container {
  width: 100%;
  text-align: center;
  .typeText {
    .caption {
      font-size: 15px;
      font-weight: normal;
      line-height: 24px;
      margin: 16px 0;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #D1636D;
      white-space: pre-wrap;
    }
    .description {
      font-size: 16px;
      font-weight: bold;
      margin-top: 16px;
    }
    .suffix {
      display: inline;
    }  
  }

  &.thin {
    position: relative;
    .caption {
      display: none;
    }
    .typeText {
      .title {
        white-space:normal;
        font-size: 18px;
        line-height: 32px;
        .suffix {
          display: none;
        }
      }
      .description{
        display: none;
      }
    }    
  }  
}