
.container {
  position: relative;
  height: 100%;
  padding: 0;
  // For temporary desktop browser support
  max-width: 500px;
  margin: 0 auto;

}
