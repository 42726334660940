@import '../Common.scss';

.share {
  text-align: center;
  margin: 16px 0;
}

.listSection {
  background-color: $bgPastel;
  font-size: 16px;
  padding: 24px;
  font-weight: bolder;
}

.referenceSection {
  border: $border solid 1px;
  border-radius: 8px;
  padding: 0 32px;
  p {
    border-bottom: $border solid 1px;
    padding: 0 0 8px 0;
    &:last-child {
      border-bottom: none;
    }
  }
}

.amazonLink {
  position: relative;
  width: 100%;
  height: 100px;
  a {
    color: $textBlack;
    &:visited {
      color: $textBlack;
    }
  }
  .amzThumbnail {
    background-color: $bgWhite;
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    text-align: center;
    vertical-align: middle;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }
  .amzTitle {
    position: absolute;
    overflow: hidden;
    font-size: 14px;
    top: 0;
    left: 116px;
    width: calc(100% - 116px);
    height: 100%;
  }
}

.box {
  box-sizing: border-box;
  padding: 24px;
  border: solid 1px $border;
  border-radius: 8px;
  .title {
    text-align: center;
    font-weight: bold;    
  }
  br {
    border-bottom: solid 1px $border;
    margin: 16px 0;
  }
}

.teacher {
  margin: 8px 0;
}

.student { 
  margin: 8px 0;
}