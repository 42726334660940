@import '../Common.scss';

.container {
  position: fixed;
  background-color: $shadePastel;
  border-top: solid 1px $shadeBrown;
  bottom: 0px;
  left: 0px;
  height: 56px;
  width: 100%;
  line-height: 56px;
  font-size: 15px;

  &.canJump {
    background-color: $accentSalmon;
  }

  .precision {
    font-size: 15px;
  }
  
  .jump {
    font-size: 14px;
    font-weight: bold;
  }
}