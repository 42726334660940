@import '../Common.scss';

.container {
  text-align: center;  
  margin: 0;
  padding: 0;
  overflow:hidden;
  white-space: pre-wrap;

  .block {
    margin: 0 24px 40px 24px;
    box-sizing: border-box;
    &.hide { display: none; }
  }
  .whiteBlock {
    background-color: $bgWhite;
    margin: 0 0 30px 0;
    padding: 10px 24px 10px 24px;
    box-sizing: border-box;
    &.hide { display: none; }
  }  
  .cardBlock {
    margin: 0 24px 40px 24px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: $bgWhite;  
    &.hide { display: none; }
  }
  .caption {
    font-size: 14px;
    color: $textSub;
    width: 300px;
    margin: 16px auto;
  }
  .caution {
    font-size: 14px;
    color: $accentRed;
    margin: 16px auto;
    font-weight: bold;
  }
}
