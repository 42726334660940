@import '../Common.scss';

.container {
  width: 100%;
  min-height: 48px;
  margin: 0;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/icon/chat_doctor.svg");
  &.imgStudent {
    background-image: url("../../assets/images/icon/chat_student.svg");
  }
  &.imgDoctor {
    background-image: url("../../assets/images/icon/chat_doctor.svg");
  }
  .text {
    font-size: 14px;
    text-align: justify;
    text-justify: inter-ideograph;
    background-color: $bgWhite;
    box-sizing: border-box;
    padding: 14px 16px;
    border: solid 1px $border;
    border-radius: 8px;
  }
  &.posLeft {
    background-position: top left;
    .text {
      margin-left: 64px;
    }
  }
  &.posRight {
    background-position: top right;
    .text {
      margin-right: 64px;
    }
  }
}