$textGray: #C3B0A2;
$textSub: #AC917B;
$textBlack: #222;
$primaryBlue: #0D76D6;
$primaryAqua: #E8F7FF;
$primarySky: #C7EBFF;
$primaryCyan: #90D7FF;
$bgPastel: #FDFAF9;
$bgWhite: #FFF;
$border: #E7DDDD;
$stroke: #CECBC9;
$shadeBrown: #CECBC9;
$shadePastel: #F6F3F1;
$accentPink: #FDDBDB;
$accentSalmon: #FFBBBD;
$accentRose: #FFA0A4;
$accentRed: #EB5959;


