@import '../Common.scss';

.container {
  box-sizing: border-box;
  background-color: $bgPastel;
  padding: 24px;
  border-top: solid 1px $border;
  .menuContainer {
    margin: 0 0 32px 0;
  }
  .copyContainer {
    text-align: right;
    .logo {
      background-image: url("../../assets/images/illust/logo.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 83px auto;
      width: 83px;
      height: 27px;
      margin: 0 0 0 auto;
      // margin: 12px auto auto auto;
    }
    .copyright {
      color: $textGray;
      font-size: 12px;
    }
  }
}
