@import '../Common.scss';

.container {
  position: relative;
  display: grid;
  row-gap: 16px;
  .row {
    position: relative;
    display: grid;
    grid-template-columns: 40px 1fr;
    column-gap: 16px;
    .rank {
      width: 40px;
      height: 40px;
      color: #ACAAA7;
      text-align: center;
      line-height: 40px;
      font-size: 20px;
      font-weight: bold;
      background-color: #FFF;
      border-radius: 20px;      
      &.gold {
        color: #FFF;
        background-color: #E4AB54;
      }
      &.silver {
        color: #FFF;
        background-color: #ACAAA7;
      }
      &.bronds {
        color: #FFF;
        background-color: #C3B0A2;
      }
    }
    .graph {
      .name {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 8px;
        text-align: left;
      }
      .bar {
        background-color: gray;
        width:100%;
        height: 12px;
        border-radius: 6px;
        &.primary {
          background-color: $primaryBlue;
        }
        &.secondary {
          background-color: #aaa;
        }
      }    
    }
    &.masked {
      -ms-filter: blur(6px);
      filter: blur(6px);  
    }
  }
  .overlay {
    position: absolute;
    left: 30px;
    top: 0;
    width: calc(100% - 60px);
    height: 210px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, .25); 
    .doctor {
      width: 48px;
      height: 48px;
      margin: 0 auto;
      background-image: url("../../assets/images/icon/chat_doctor.svg");
    }
    .text {
      font-size: 14px;
      margin: 16px 0;
    }
    .buttons {
      display: inline-block;
      width: 100%;
    }
  }
}
