@import '../Common.scss';

.container {
  margin: 0;
  text-align: left;

  .MenuItem {
    font-size: 16px;
    line-height: 40px;
    &.small {
      font-size: 14px;
      line-height: 35px;
    }
  }
}
