@import '../Common.scss';

.container {
  position: relative;
  width: 100%;
  height: 200px;
  margin: 0 auto;
  .graph {
    width: 100%;
    height: 100%;
  }
  &.thin{
    height: 120px;
  }
}

.curvePath {
  stroke: $primaryBlue;
  stroke-width: 2;
  fill: none;
}

.dataPath {
  stroke: $primaryBlue;
  stroke-width: 1;
  fill: none;
}

.dataPlot {
  fill: $primaryBlue;
}

.axisX line {
  stroke: none;  
}

.axisX path {
  stroke: $border;
}

.axisY line {
  stroke: $border;
}

.axisY path {
  stroke: none;
}
