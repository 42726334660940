@import '../Common.scss';

.container {
  position: relative;
  background-color: $bgPastel;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  .b {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 0px;
    right: 0px;
  }
}
