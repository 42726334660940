@import '../Common.scss';

.container {
  .stampImage {
    width: 100%;
    height: auto;
  }
  .button {
    text-align: center;
  }
  .red {
    font-weight: bolder;
    color: #ED1D1D;
  }
}
