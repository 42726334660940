@import '../Common.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  // background-color: rosybrown;

  .itemText {
    // background-color: aqua;
    height: 48px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    padding: 3px 32px;
  }
  .usagiContainer {
    text-align: center;
    height: 55%;
    margin: 0 auto;
    img {
      height: 100%;
      width: 100%;
      
      // width: 100%;
      &.hidden {
        display: none;
      }
    }
  }
  .sliderContainer {
    position: absolute;
    bottom: 8px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 32px;
    // bottom: 0;
    // margin: 16px 0;
  }
}
