@import '../Common.scss';

.container {
  margin: 0;
  padding: 16px 0;
  background-color: $bgPastel;
  min-height: 100%; 
  .usagiDoctor {
    background-image: url("../../assets/images/illust/doctor.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 240px;
    height: 240px;
    margin: 16px auto 16px auto;
  }
  .suggest {
    box-sizing: border-box;
    text-align: left;
    background-color: $bgWhite;
    border: solid 1px $stroke;
    border-radius: 8px;
    padding: 16px;
    // width: 280px;
    margin: 0 16px;
    font-size: 16px;
    font-weight: bold;
    white-space: pre-wrap;
  }  
}
