@import '../Common.scss';

.container{
  padding: 16px;
  text-align: center;
  h3 {
    text-align: center;
    margin: 24px 0;
  }
  .filterGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;    
    column-gap: 8px;
    row-gap: 8px;
    margin-bottom: 32px;;
    .filter {
      position: relative;
      text-align: center;
      border: solid 1px $border;
      border-radius: 4px;
      height: 30px;
      .filterName {
        font-size: 15px;
        font-weight: bold;
        line-height: 30px;
      }
      &.selected {
        background-color: $stroke;
      }
    }
  }
}