@import '../Common.scss';

.container {
  background-color: $bgWhite;
  text-align: center; 
  width: 300px;
  margin: 0 auto;
  padding: 8px;
  border-radius: 16px;
  .buttons {
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-content: center;    
    grid-template-columns: 56px 56px 56px 56px;
    .button {
      width: 32px;
      margin: 0 auto;      
    }
    img {
      width: 32px;
      height: 32px;
    }
  
  }
}
