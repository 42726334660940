@import '../Common.scss';

.container{
  .suggestList {
    padding: 0 16px;
    margin-bottom: 32px;
    h2 {
      margin: 32px;
      text-align: center;
    }
    &.hide { display: none; }
  }
  .articleActions {
    margin: 32px 0;
    .actionButton {
      margin: 16px auto;
      width: 240px;
      &.hide { display: none; }
    }  
  }

}