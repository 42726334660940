@import '../Common.scss';

.container {
  position: fixed;
  top: 0px;
  left: 0px;
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 56px;
  overflow: hidden;
  background-color: $bgPastel;

  .alphaMark {
    position: absolute;
    top: 18px;
    left: 18px;
    font-size: 10px;
    height: 20px;
    width: 56px;
    line-height: 20px;
    color: $textGray;
    border: solid 1px $textGray;
  }

  .logo {
    background-image: url("../../assets/images/illust/logo.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 200px;
    height: 32px;
    margin: 12px auto auto auto;
  }
}
